<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


import Choices from "choices.js";

import ExpenseAutoComplete from '@/components/fee-auto-complete-input.vue'
import AgentAutoComplete from '@/components/agent-auto-complete-input'
import UAEditDialog from './components/rebgv_ua_edit_dialog.vue'
import MUEditDialog from './components/rebgv_mu_edit_dialog.vue'
import {
    required,
} from "vuelidate/lib/validators";

import moment from 'moment'

import { getBrokerageApi } from "@/api/brokerage";
import { financial } from '@/api/misc'
import { getArApApi } from "@/api/cash";


export default {
    page: {
        title: "Create AP Western ",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },

    validations: {
        expense: {
            post_date: { required },
        },
    },


    data() {
        return {
            title: "Create Board Exepnse",
            items: [
                {
                    text: "AP List",
                    href: "/ap/list",
                },
                {
                    text: "Create Board Expense",
                    href: "/ap/list",
                },
            ],

            expense: {
                supplier_id: '',
                expense: 0,
                invoice: ''
            },

            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 50,
            },


            office_choice: {},
            office_list: [],


            fee_choice : {},
            fee_list : [],

            gl_entry_list: [],
            charge_agent_list: [],
            selected_agent: {},
            charge_agent_amount: 0,
            charge_agent_gst   : 0,
            part3_mu_invoice   :'',
            query_month: '',


            fee_amount : '',
            fee_gst    : '',
            current_fee : '',
            current_agent : {},

            sub_office_name : '',
            sub_office_code : '',

            part_1_list_sortDesc : false,
            part_1_list_sortBy : '',


            total_amount: 0,
            is_submitting: false,

            regbv_gl     : '21041',
          
            mybd         : {
                amount  : 0,
                gst     : 0,
                total   : 0
            },

            myua       : {
                amount  : 0,
                gst     : 0,
                total   : 0,
                list    : []
            },


            part_2_ua   : [],

            part_2_list : [],
            part_3_list : [],
            part_3_obj  : {
                amount  : 0,
                gst     : 0,
                total   : 0,
            },

            native_tmp_id : 10000,

        }
    },

    components: {
        Layout,
        PageHeader,
        flatPickr,
       
        AgentAutoComplete,
        ExpenseAutoComplete,
        UAEditDialog,
        MUEditDialog


    },

    methods: {

        f(v) {
            return financial(v, 2)
        },

        confirmToSubmit(fn, msg = 'Are you sure to submit?') {
            this.$alertify.confirmWithTitle(
                '', msg,
                () => {
                    this.formSubmit()
                },
                () => {
                }
            );
        },



        formSubmit() {

            this.$v.$touch();

            if (this.$v.$invalid == false) {
                this.is_submitting = true

                let part_1_list = []
                this.charge_agent_list.map(e => {

                    e.mybd_list.map(sub_e => {
                        part_1_list.push({
                            agent_id  : sub_e.agent_id,
                            amount    : sub_e.amount,
                            gst       : sub_e.gst,
                            exp_code  : sub_e.fee_code,
                            remarks   : this.expense.description,
                        })
                    })

                  
                })

                let part_2_details = this.part_2_list.filter(e => e.is_ua == false)
                let part_2_ua_list = []

                this.part_2_ua.map(e => {
                    part_2_ua_list.push({
                        agent_id    : e.agent_id,
                        amount      : e.amount,
                        gst         : e.gst,
                        ua_id       : e.id,
                        remarks     : e.remarks
                    })
                })

                let data = {
                    office_code            : this.expense.office_code,
                    amount                 : Number(this.total_amount),
                    payee_type             : 'SUPPLIER',
                    description            : this.expense.description,
                    post_date              : this.expense.post_date,
                    expense_details        : this.gl_entry_list,
                    agent_supplier_records : part_1_list,
                    regbv_fees             : [],
                    ua_list                : part_2_ua_list,
                    extra_ua_fees          : [],
                    part2_details          : part_2_details,
                    mu_list                : this.part_3_list,
                    regbv_transaction_fees : []
                }
               

                getArApApi().create_regbv_expense(data).then((res) => {
                    if (res.errCode == 0) {
                        this.$alertify.message("Post Succeed ");
                        this.$router.push({ name: 'ap-general' })
                    } else {
                        this.$alertify.error("Failed to Update " + res.errCode);
                    }
                    this.is_submitting = false
                })
            }
        },


        onExpenseSelected(evt) {
            this.current_fee = evt.fee
        },

       
        onAddPart2GL() {
            let tmp_id = this.native_tmp_id
            let amount = financial(Number(this.fee_amount), 2) 
            this.part_2_list.push({ gl_number: this.current_fee.accounting_gl,  gl_label: this.current_fee.name, amount: amount, tmp_id : tmp_id, exp_code : this.current_fee.code, is_ua : false, is_gst : false })
            this.gl_entry_list.push({ gl_number: this.current_fee.accounting_gl,  gl_label: this.current_fee.name, amount: amount, tmp_id : tmp_id, exp_code : this.current_fee.code,  is_ua : false, is_gst: false })

            if (this.current_fee.tax_flag && Number(this.fee_gst) != 0) {
                this.part_2_list.push({ gl_number: this.current_fee.accounting_gst_gl,  gl_label: this.current_fee.name, amount: Number(this.fee_gst),  tmp_id : tmp_id, exp_code : this.current_fee.code,  is_ua : false, is_gst : true})
                this.gl_entry_list.push({ gl_number: this.current_fee.accounting_gst_gl,  gl_label: this.current_fee.name, amount: Number(this.fee_gst),  tmp_id : tmp_id, exp_code : this.current_fee.code,  is_ua : false, is_gst : true})
            }

            this.native_tmp_id += 1
        },

        onRemovePart2Fee(item) {
            this.part_2_list = this.part_2_list.filter(e => e.tmp_id != item.tmp_id)
        },


        on_ua_edit_result(ua_list) {
            this.part_2_ua = []
            this.part_2_ua.push(...ua_list)
            this.updatePart2JEUA()
            this.$bvModal.hide('modal-ua-edit-dialog')

        },
    

      
        onAddChargedPart1Agent() {
           
            let cache = this.charge_agent_list.find(e => e.agent_id == this.current_agent.id)
            if (!cache) {
                this.charge_agent_list.push({
                    agent_id   : this.current_agent.id,
                    agent_name : this.current_agent.first_name +" " + this.current_agent.last_name,

                    mybd_amount : Number(this.charge_agent_amount),
                    mybd_gst    : this.f(Number(this.charge_agent_gst), 2),
                    mybd_total  : this.f(Number(this.charge_agent_amount) +  Number(this.charge_agent_gst), 2)
                })
            }
            this.updatePart1JE()
        },

        onRemovePart1Agent(ag) {
            this.charge_agent_list.splice(ag.index, 1)
            this.updatePart1JE()
        },


        onSelectedAgent(evt) {
            this.current_agent = evt.agent
        },

        query_recoverable_fee_list() {
            let data = {
                office_code : this.expense.office_code,
                query_month : this.query_month,
            }
            let new_mu_list = []
            getArApApi().query_regbv_agent_fee_list(data).then(res => {
               

                if (res.errCode == 0) {
                    this.part_2_ua  = []

                   
                    this.charge_agent_list = res.data
                    this.charge_agent_list.map(e => {
                        e.mybd_total  = e.mybd_list.reduce((a, c)=> a+(Number(c.amount) + Number(c.gst)), 0)
                        e.mybd_amount = e.mybd_list.reduce((a, c)=> a+(Number(c.amount) ), 0)
                        e.mybd_gst    = e.mybd_list.reduce((a, c)=> a+(Number(c.gst) ), 0)

                        e.myua_total  =  e.myua_list.reduce((a, c)=> a+(Number(c.amount) + Number(c.gst)), 0)
                        e.myua_amount =  e.myua_list.reduce((a, c)=> a+(Number(c.amount)), 0)
                        e.myua_gst    =  e.myua_list.reduce((a, c)=> a+(Number(c.gst)), 0)

                        this.part_2_ua.push(...e.myua_list)

                        new_mu_list.push(...e.mymu_list)
                       
                    })

                    this.on_mu_edit_result(new_mu_list)



                    this.mybd.amount = this.f(this.charge_agent_list.reduce((a, c) => a + (c.mybd_amount), 0), 2)
                    this.mybd.gst    = this.f(this.charge_agent_list.reduce((a, c) => a + (c.mybd_gst), 0), 2)
                    this.mybd.total  = this.f(this.mybd.amount + this.mybd.gst, 2)

                    this.myua.amount = this.f(this.charge_agent_list.reduce((a, c) => a + (c.myua_amount), 0), 2)
                    this.myua.gst    = this.f(this.charge_agent_list.reduce((a, c) => a + (c.myua_gst), 0), 2)
                    this.myua.total  = this.f(this.myua.amount + this.myua.gst, 2)


                    this.updatePart1JE()
                    this.updatePart2JEUA()
                }
            })
        },

    

       getAgentPart1FootValue(column) {
            if (column.column =='Amount') {
                return '$'+financial(this.mybd.amount, 2).toLocaleString()
            } else if (column.column =='GST') {
                return '$'+financial(this.mybd.gst, 2).toLocaleString()
            } else if (column.column =='Total') {
                return '$'+financial(this.mybd.total, 2).toLocaleString()
            }
        },

        getAgentPart3FootValue(column) {
            if (column.column =='Amount') {
                return '$'+financial(this.part_3_obj.amount, 2).toLocaleString()
            } else if (column.column =='GST') {
                return '$'+financial(this.part_3_obj.gst, 2).toLocaleString()
            } else if (column.column =='Total') {
                return '$'+financial(this.part_3_obj.total, 2).toLocaleString()
            }
        },


        on_mu_edit_result(mu_list) {

            this.part_3_list = []
            this.part_3_list.push(...mu_list)
           
        
            this.part_3_obj.amount = this.part_3_list.reduce ((a, c) => a +c.amount, 0)
            this.part_3_obj.gst    = this.part_3_list.reduce ((a, c) => a +c.gst, 0)
            this.part_3_obj.total  = this.part_3_list.reduce ((a, c) => a +c.total, 0)
            this.updatePart3JE()

            this.$bvModal.hide('modal-mu-edit-dialog')
        },

        onRemovePart3Fee(item) {
            this.part_3_list.splice(item.idx, 1)
            this.part_3_obj.amount = this.part_3_list.reduce ((a, c) => a +c.amount, 0)
            this.part_3_obj.gst    = this.part_3_list.reduce ((a, c) => a +c.gst, 0)
            this.part_3_obj.total  = this.part_3_list.reduce ((a, c) => a +c.total, 0)
            this.updatePart3JE()
        },


       



        updatePart3JE() {
            let mu_je = this.gl_entry_list.find(e => e.gl_number == '21041' && e.is_mu == true)
            mu_je.amount = this.f(Number(this.part_3_obj.total), 2)
        },


        updatePart1JE() {

            this.mybd.amount = this.charge_agent_list.reduce((a, c) => a+c.mybd_amount, 0)
            this.mybd.gst    = this.charge_agent_list.reduce((a, c) => a+c.mybd_gst, 0)
            this.mybd.total  = this.charge_agent_list.reduce((a, c) => a+c.mybd_total, 0)

            let gl_entry     = this.gl_entry_list.find(e => e.gl_number == this.regbv_gl && e.is_part1 == true)
            gl_entry.amount  = this.charge_agent_list.reduce((a, c) => a+c.mybd_total, 0)
        },


        updatePart2JEUA() {
            this.myua.amount = this.part_2_ua.reduce((a, c) => a+c.amount, 0)
            this.myua.gst    = this.part_2_ua.reduce((a, c) => a+c.gst, 0)
            this.myua.total  = this.part_2_ua.reduce((a, c) => a+(Number(c.amount) + Number(c.gst)), 0)


            let gl_entry    = this.gl_entry_list.find(e => e.gl_number == this.regbv_gl && e.is_part2 == true)
            gl_entry.amount = this.part_2_ua.reduce((a, c) => a+ (Number(c.amount) + Number(c.gst)), 0)


            let ua_je    = this.part_2_list.find(e => e.gl_number == '21041' && e.is_ua == true)
            if (!ua_je) {
                ua_je = {
                    gl_number : '21041',
                    gl_label  : 'BOARD DUE  - UA',
                    amount    : 0,
                    is_ua     : true
                    
                }
                this.part_2_list.push(ua_je)
            }
            ua_je.amount = gl_entry.amount

        },


        part_1_list_sort() {
            this.charge_agent_list.sort((a, b) => {
                if (this.part_1_list_sortBy == 'Name') {
                    if (this.part_1_list_sortDesc) {
                        return a.name > b.name ? -1 : 1
                    } else {
                        return a.name > b.name ? 1 : -1
                    }
                } else if (this.part_1_list_sortBy == 'VIN') {
                    if (this.part_1_list_sortDesc) {
                        return a.agent_vin > b.agent_vin ? -1 : 1
                    } else {
                        return a.agent_vin > b.agent_vin ? 1 : -1
                    }
                } 
            })
        },


    },


    created() {
        moment()
       
        this.gl_entry_list.push({
            gl_number : '21000',
            gl_label  : 'AP Supplier',
            amount    : 0,
        })

        this.gl_entry_list.push({
            gl_number : '21041',
            gl_label  : 'Board Due',
            amount    : 0,
            is_part1  : true,
        })

        this.gl_entry_list.push({
            gl_number : '21041',
            gl_label  : 'BOARD DUE - UA',
            amount    : 0,
            is_part2  : true,
        })

        this.gl_entry_list.push({
            gl_number : '21041',
            gl_label  : 'BOARD DUE - MU',
            amount    : 0,
            is_mu     : true,
        })

        getBrokerageApi().officeApi.list({ page: { page: 1, page_size: 50 } }).then((res) => {
            if (res.errCode == 0) {
                this.office_list = []
                res.data.map((s) => {
                    this.office_list.push({
                        label: s.branch_name,
                        value: s.office_code,
                        c: s
                    })
                })

                this.office_choice.clearChoices()
                this.office_choice.setChoices(this.office_list)

            }
        })



    },

    mounted() {
       
        this.office_choice = new Choices('#choices-office-input', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })


    },


    watch: {
        total_amount  (new_value) {
            let supplier_gl =  this.gl_entry_list.find(e => e.gl_number == '21000')
            if (supplier_gl) {
                supplier_gl.amount = -Number(new_value)
            }
        },

        fee_amount (new_value) {
            this.fee_gst = financial(Number(new_value) * 0.05, 2)
        },

        charge_agent_amount (new_value) {
            this.charge_agent_gst = financial(Number(new_value) * 0.05, 2)
        },

        part_1_list_sortBy() {
            this.part_1_list_sort()
        },
        part_1_list_sortDesc() {
            this.part_1_list_sort()
        },
    },
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <b-modal centerd id="modal-ua-edit-dialog" title="UA Edit" size="lg" hide-footer>
            <UAEditDialog :ua_list="part_2_ua" @cancel="$bvModal.hide('modal-ua-edit-dialog')" @confirm="on_ua_edit_result"  />
        </b-modal>

        <b-modal centerd id="modal-mu-edit-dialog" title="MU List Edit" size="lg" hide-footer>
            <MUEditDialog :mu_list="part_3_list" @cancel="$bvModal.hide('modal-mu-edit-dialog')" @confirm="on_mu_edit_result"  />
        </b-modal>

        
        <div class="card">
            <div class="card-body">
                <form @submit.prevent="confirmToSubmit(formSubmit)">


                    <div class="row">

                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-office-input" class="form-label">Office</label>
                                <select class="form-control" v-model="expense.office_code" name="choices-office-input"
                                    id="choices-office-input">
                                </select>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="mb-3">
                                <label>Total Amount</label>
                                <input id="validationCustom01" v-model="total_amount" type="text" class="form-control"
                                    placeholder="$" value=""  />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="validationCustom03">Post Date</label>
                                <flat-pickr v-model="expense.post_date" placeholder="Select a date" class="form-control"
                                    :class="{ 'is-invalid': $v.expense.post_date.$error,  }"></flat-pickr>
                                <div v-if="$v.expense.post_date.$error" class="invalid-feedback">
                                    <span v-if="!$v.expense.post_date.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        

                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="validationCustom01">Description</label>
                                <input id="validationCustom01" v-model="expense.description" type="text"
                                    class="form-control" placeholder="" value="" />
                            </div>
                        </div>

                    </div>
                    <!-- end row-->

                    <div class="row mt-5" v-if="is_submitting">
                        <b-alert show variant="danger">
                            <b-spinner variant="danger" label="Spinning" class="me-2"></b-spinner>
                            Submitting Charge Data, please waiting.....
                        </b-alert>
                    </div>

                    <hr class="my-4 mb-3" />
                    <div class="row mt-5"></div>


                    <div class="row mt-5">
                        <b-tabs class="mt-n5 mt-5" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
                            nav-wrapper-class="nav-tabs-custom">
                            <b-tab title="Accounting">
                                <div class="table-responsive">
                                    <div class="table align-middle table-nowrap">
                                        <b-table :items="gl_entry_list" :fields="[{ key: 'GL' }, { key: 'Amount' }]"
                                            responsive="sm" :per-page="gl_entry_list.length" class="table-check">

                                            <template #cell(GL)="data">
                                                {{ data.item.gl_number + '('+data.item.gl_label+')' }}
                                            </template>
                                            <template #cell(Amount)="data">
                                                ${{ data.item.amount.toLocaleString() }}
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                            </b-tab>

                            <b-tab title="Agent(Part 1)" >
                                <div class="row">
                                  
                                    <div class="col-md-2">
                                        <div class="mb-3">
                                            <label for="validationCustom03">Query Month</label>
                                            <flat-pickr v-model="query_month" placeholder="Select a month" :config="{dateFormat: 'Ym'}"
                                            @on-change="query_recoverable_fee_list" class="form-control"></flat-pickr>

                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="mb-3">
                                            <label for="choices-fee-input" class="form-label">Agent</label>
                                            <AgentAutoComplete  @onSelected="onSelectedAgent"  autocomplete="off"  />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-3">
                                            <label for="choices-fee-input" class="form-label">Amount</label>
                                            <input type="text" v-model="charge_agent_amount" class="form-control" placeholder="$" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-3">
                                            <label for="choices-fee-input" class="form-label">Gst</label>
                                            <input type="text" v-model="charge_agent_gst" class="form-control" placeholder="$" />
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="mb-3 mt-4">
                                            <b-button variant="secondary" type="button" @click="onAddChargedPart1Agent">Add Agent</b-button>
                                        </div>
                                    </div>
                                </div>
                                <!-- end row -->

                                <div class="table-responsive">
                                    <b-table :items="charge_agent_list"
                                        :fields="[{key:'Name', sortable : true}, {key:'VIN', sortable : true}, 'Amount', 'GST', 'Total', 'Action']"
                                        :sort-by.sync="part_1_list_sortBy"  :sort-desc.sync="part_1_list_sortDesc"
                                        responsive="sm" :per-page="charge_agent_list.length" class="table-check datatables" foot-clone>
                                        <template #cell(Name)="data">
                                            {{ data.item.agent_name }}
                                        </template>
                                        <template #cell(VIN)="data">
                                            {{ data.item.agent_vin }}
                                        </template>
                                       
                                        <template #cell(Amount)="data">
                                            ${{ f(data.item.mybd_amount).toLocaleString() }}
                                        </template>
                                       
                                        <template #cell(GST)="data">
                                            ${{ f(data.item.mybd_gst).toLocaleString() }}
                                        </template>

                                        <template #cell(Total)="data">
                                            ${{ f(data.item.mybd_total).toLocaleString() }}
                                        </template>

                                        <template #cell(Action)="data">
                                            <b-button variant="outline-danger" size="sm"
                                                @click="onRemovePart1Agent(data)">
                                                <i class="mdi mdi-close"></i>
                                            </b-button>
                                        </template>

                                        <template #foot()="data">
                                            <i>{{ getAgentPart1FootValue(data) }}</i>
                                        </template>

                                    </b-table>
                                </div>
                            </b-tab>
                            <!-- End Part 1 Tab-->

                            <b-tab title="Credit(Part 2)">

                                <div class="row" >
                                    <div class="col-md-12">
                                        
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <label for="choices-fee-input" class="form-label">Break Down GL</label>
                                                    <ExpenseAutoComplete :s_type="'Expense'" @onSelected="onExpenseSelected"/>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <label for="choices-fee-input" class="form-label">Amount</label>
                                                    <input type="text" v-model="fee_amount" class="form-control" placeholder="$" />
                                                </div>
                                            </div>


                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <label for="choices-fee-input" class="form-label">Gst</label>
                                                    <input type="text" v-model="fee_gst" class="form-control" placeholder="$" />
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="mb-3 mt-4">
                                                    <b-button variant="secondary" type="button" @click="onAddPart2GL">Add Entry</b-button>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="mb-3 mt-4">
                                                    <b-button variant="primary" type="button" v-b-modal.modal-ua-edit-dialog>Edit UA List</b-button>
                                                </div>
                                            </div>

                                        </div>
                                        <!-- end row-->
                                    </div>
                                
                                </div>
                                <!-- end row-->
                                <div class="table-responsive">
                                    <div class="table align-middle table-nowrap">
                                        <b-table :items="part_2_list" :fields="['GL','Amount','Action']"
                                            responsive="sm" :per-page="part_2_list.length" class="table-check" >

                                            <template #cell(GL)="data">
                                                {{ data.item.gl_number + '('+ data.item.gl_label+')' }}
                                            </template>
                                          
                                            <template #cell(Amount)="data">
                                                ${{ data.item.amount.toLocaleString() }}
                                            </template>
                                          
                                            <template #cell(Action)="data">

                                                <b-button variant="outline-danger" size="sm" @click="onRemovePart2Fee(data.item)" v-if="data.item.is_ua  != true">
                                                <i class="mdi mdi-close"></i>
                                                </b-button>
                                            </template>

                                        </b-table>
                                    </div>
                                </div>
                            </b-tab>
                            <!-- end Part2 Tab-->

                            <b-tab title="MU(Part 3)">
                                <div class="row">

                                    <div class="col-md-2">
                                        <div class="mb-3">
                                            <label for="validationCustom03">Query Month</label>
                                            <flat-pickr v-model="query_month" placeholder="Select a month" :config="{dateFormat: 'Ym'}"
                                            @on-change="query_recoverable_fee_list" class="form-control"></flat-pickr>

                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="mb-3 mt-4">
                                            <b-button variant="secondary" type="button" v-b-modal.modal-mu-edit-dialog>Edit MU List</b-button>
                                        </div>
                                    </div>
                                </div>
                                <!-- end row -->

                                <div class="table-responsive">
                                    <div class="table align-middle table-nowrap">
                                        <b-table :items="part_3_list" :fields="['Agent','Invoice','Address', 'CompletionDate', 'Amount','GST','Total','Action']"
                                            responsive="sm" :per-page="part_3_list.length" class="table-check" foot-clone>

                                            <template #cell(Agent)="data">
                                                {{ data.item.agent_name }}
                                            </template>
                                          
                                            <template #cell(Invoice)="data">
                                                {{ data.item.invoice }}
                                            </template>
                                            <template #cell(Address)="data">
                                                {{ data.item.address }}
                                            </template>
                                            <template #cell(CompletionDate)="data">
                                                {{ data.item.completion_date }}
                                            </template>
                                            <template #cell(Amount)="data">
                                                ${{ data.item.amount.toLocaleString() }}
                                            </template>
                                            <template #cell(GST)="data">
                                                ${{ data.item.gst.toLocaleString() }}
                                            </template>
                                            <template #cell(Total)="data">
                                                ${{ (data.item.gst + data.item.amount).toLocaleString() }}
                                            </template>
                                            <template #cell(Action)="data">
                                                <b-button variant="outline-danger" size="sm" @click="onRemovePart3Fee(data)" >
                                                <i class="mdi mdi-close"></i>
                                                </b-button>
                                            </template>
                                            <template #foot()="data">
                                                <i>{{ getAgentPart3FootValue(data) }}</i>
                                            </template>

                                        </b-table>
                                    </div>
                                </div>
                            </b-tab>


                        </b-tabs>

                    </div>
                    <!-- end row-->

                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center">
                                <b-button variant="secondary" type="button" @click="$router.push({name : 'ap-general'})">Cancel</b-button>
                                <b-button variant="primary" type="submit" :disabled="is_submitting== true">Submit</b-button>
                            </div>
                        </div> 
                    </div>
                    <!-- end row-->


                </form>
            </div>
        </div>
        <!-- end card -->
    </Layout>
</template>

<style scoped>
  #invoice_dropzone {
    letter-spacing: 0.2px;
    height: 150px;
  }
</style>