<script>


/**
 * Cheque Print  component
 */


import {
    required,
} from "vuelidate/lib/validators";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import simplebar from "simplebar-vue";
import { financial } from '@/api/misc'

export default {

    emits: ['confirm', 'cancel'],

    props: ['mu_list'],
    components: {
        simplebar,
        AgentAutoComplete,
        flatPickr
    },

    validations: {
        amount: {required },
        invoice : {required},
    },


    data() {

        return {
            submitted: false,
            amount   : 0,
            gst      : 0,
          
            current_agent : {},
            note          : '',


            invoice       :'',
            address       :'',
            completion_date : '',     
        }

    },
    methods: {
        formSubmit() {
           
            this.$emit('confirm', this.mu_list)
            
        },


        onSelectedAgent(evt) {
            this.current_agent = evt.agent
        },

        onAddUA() {
            this.$v.$touch();

            if (this.$v.$invalid == false) {
                if (!this.current_agent.id) {
                    return
                }
                this.mu_list.push({
                    amount     : financial(Number(this.amount), 2),
                    gst        : financial(Number(this.gst), 2),
                    total      : financial(Number(this.gst) + Number(this.amount), 2),
                    agent_name : this.current_agent.first_name +' ' + this.current_agent.last_name,
                    note       : this.note,
                    agent_id   : this.current_agent.id,
                    invoice    : this.invoice,
                    address    : this.address,
                    completion_date : this.completion_date,

                })

                this.$v.$reset();
                this.invoice =''

            }

            
        },

        onRemoveUA(data) {
            this.mu_list.splice(data.index, 1)
        },

        getUADialogFoot(column) {
            if (column.column =='Amount') {
                return '$'+financial(this.mu_list.reduce((a,c ) => a + c.amount, 0), 2).toLocaleString()
            } else if (column.column =='Gst') {
                return '$'+financial(this.mu_list.reduce((a,c ) => a + c.gst, 0), 2).toLocaleString()
            } 
        }


       

    },

    created() {


    },

    mounted() {

    },

    watch: {
        amount(new_val) {
            this.gst = financial(Number(new_val) * 0.05, 2)
        }
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="choices-fee-input" class="form-label">Agent</label>
                            <AgentAutoComplete  @onSelected="onSelectedAgent"  autocomplete="off"  />
                        </div>
                    </div>
                    

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="choices-fee-input" class="form-label">Amount</label>
                            <input type="text" v-model="amount" class="form-control" placeholder="$" :class="{ 'is-invalid': $v.amount.$error,  }" />
                            <div v-if="$v.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.amount.required">Required</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="choices-fee-input" class="form-label">Gst</label>
                            <input type="text" v-model="gst" class="form-control" placeholder="$" />
                        </div>
                    </div>

                   

                    <div class="col-md-2">
                        <div class="mb-3 mt-4">
                            <b-button variant="secondary" type="button" @click="onAddUA">Add  Entry</b-button>
                        </div>
                    </div>
                </div>
                <!-- end row -->


                <div class="row">
                    
                    

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="choices-fee-input" class="form-label">Invoice</label>
                            <input type="text" v-model="invoice" class="form-control" placeholder="" :class="{ 'is-invalid': $v.invoice.$error,  }" />
                            <div v-if="$v.invoice.$error" class="invalid-feedback">
                                <span v-if="!$v.invoice.required">Required</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="choices-fee-input" class="form-label">Address</label>
                            <input type="text" v-model="address" class="form-control" placeholder="" />
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="choices-fee-input" class="form-label">Completion Date</label>
                            <flat-pickr v-model="completion_date" placeholder="Select a date" class="form-control"></flat-pickr>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="choices-fee-input" class="form-label">Note</label>
                            <input type="text" v-model="note" class="form-control" placeholder="" />
                        </div>
                    </div>

                </div>
                <!-- end row -->




              
                <simplebar class="table-container" data-simplebar-auto-hide="false">
                    <div class="table-responsive" style="max-height: 400px;">
                        <div class="table align-middle table-nowrap" style="height:100%" >
                            <b-table :items="mu_list" :fields="['Agent','Amount', 'Gst','Invoice','Address','Completion','Note', 'Action']" responsive="sm" :per-page="mu_list.length" 
                            class="table-check" foot-clone>
                                <template #cell(Agent)="data">
                                    {{ data.item.agent_name }}
                                </template>

                                <template #cell(Amount)="data">
                                    ${{ data.item.amount.toLocaleString() }}
                                </template>

                                <template #cell(Gst)="data">
                                    ${{ data.item.gst.toLocaleString() }}
                                </template>
                                <template #cell(Invoice)="data">
                                    {{ data.item.invoice }}
                                </template>
                                <template #cell(Address)="data">
                                    {{ data.item.address }}
                                </template>
                                <template #cell(Completion)="data">
                                    {{ data.item.completion_date }}
                                </template>
                                <template #cell(Note)="data">
                                    {{ data.item.note }}
                                </template>
                                <template #cell(Action)="data">
                                    <b-button variant="outline-danger" size="sm" @click="onRemoveUA(data)" >
                                        <i class="mdi mdi-close"></i>
                                    </b-button>
                                </template>
                                <template #foot()="data">
                                    <i>{{ getUADialogFoot(data) }}</i>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </simplebar>

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit" >Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>